import aboutThumb1 from "../../images/team/1.jpeg";
import aboutThumb2 from "../../images/team/2.jpg";
import aboutIcon from "../../images/icon/ab-icon-image.png";

const data = {
  aboutDescription1:
    "Rich Simmons is a Contemporary Urban Pop Artist who has exhibited all over the world. Simmons work explores the intersections of visual culture, spanning pop art, comic books, the Renaissance, contemporary fashion, sexuality and beyond.",
  aboutDescription2:
    "London-based Rich Simmons has a global reach and celebrity following, having exhibited in some of the world’s most prestigious galleries in London, New York, LA, Tampa, Miami, Montreal, Toronto, Seoul and Geneva. Simmons work has now made it into Museum collections with the Men Of Steel, Women Of Wonder exhibition debuting at the Crystal Bridges Museum of American Art in 2019.",
  aboutDescription3:
    "In 2018, a painting created for Gracious Hearts Charity for children with cerebral palsy sold for £52,000 at a Christie’s charity auction.",
  aboutDescription4:
    "2022 saw the launch of Simmons NFT debut, with Reflections selling out the 3,333 mint in under an hour and going on to generate $1.8 million in sales on Opensea in the first month. The collection debuted in the top 10 Art NFTs on Opensea and earned finalist spots in the NFT NYC Awards as Best NFT Artist and Best Emerging NFT Artist.",
  aboutDescription5:
    "Simmons bold use of colour, intricately detailed hand-cut stencils, sense of humour and thought-provoking narratives running through his work are proving Rich is both an innovator and highly collectible artist. Simmons work transcends galleries and his street art murals can be seen around the world with the latest 3,400 sq ft mural being completed in Rogers Arkansas.",

  artists: [
    {
      thumb: aboutThumb1,
      icon: aboutIcon,
      name: "Rich Simmons",
      role: "Artist",
      description: ""
    },
    
  ],
};

export default data;
