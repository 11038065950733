const data = [
  {
    title: "Where can I mint?",
    text: "They will only be available through out official website - https://reflectionsnoir.io/ ",
  },
  {
    title: "How many NFTs will there be?",
    text: "There will be a limited quantity of 999 NFTs available to purchase.",
  },
  {
    title: "Which blockchain will they be launched on?",
    text: "This collection has been created on the Ethereum blockchain using our gas-efficient ERC721a smart contract.",
  },
  {
    title: "Are there any giveaways?",
    text: "Indeed, we will perform regular giveaways to our holders and notify owners through our social channels.",
  },
  {
    title: "How much will it cost to mint 1 NFT?",
    text: "Presale Price - Free Just pay Gas (Max 1 per wallet) / Public Price - 0.08 ETH (Max 2 per wallet).",
  },
  {
    title: "Which secondary marketplace will they trade on?",
    text: "After minting, the entire collection will be available to trade on OpenSea / Rarible / LooksRare.",
  },
  {
    title: "Are any NFTs being held back for the team, marketing, etc?",
    text: "Yes, a handfull of NFTs will be held back for marketing and airdrops.",
  },
];

export default data;
