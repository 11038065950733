import { useModal } from "../../../../utils/ModalContext";
import Button from "../../../../common/button";
import BannerV1Wrapper from "./Banner.style";
import { useEffect, useState } from "react";
import contract from '../../../../contracts/bithuabi.json';
import { ethers } from 'ethers';
import logo from "../../../../assets/images/logo.png";
import { web3 } from 'web3';
import { totalMintCount, currentPrice } from '../../../../utils/web3mint';
import { isMetaMaskInstalled, ethereum, contractAddress, isWalletAlreadyConnected } from '../../../../config';

const Banner = () => {
  const { mintModalHandle, connectWalletModalHanlde, account } = useModal();
  const [totalMinted, setTotalMinted] = useState(0);
  const [cost, setCost] = useState(0);
  const [userMintedCount, setUserMintedCount] = useState(0);

  const getTotalMints = async () => {
    if(isMetaMaskInstalled()){
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const nftContract = new ethers.Contract(contractAddress(), contract, signer);

      await nftContract.totalSupply().then(async (supply) => {
        setTotalMinted(supply.toNumber());
    
        return supply.toNumber();
      });
    }
  }

  const getPrice = async () => {
    if(isMetaMaskInstalled()){
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const nftContract = new ethers.Contract(contractAddress(), contract, signer);
      await nftContract.cost().then(async (thecost) => {
        let newCost = ethers.utils.formatEther(thecost.toString());
        
        setCost(newCost);

        localStorage.setItem('cost', newCost);

        return newCost;
      });
    }
  }

  useEffect(() =>{
      getTotalMints();
      getPrice();
  },[]);

  return (
    <BannerV1Wrapper id="home">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="bithu_v1_baner_left">
              <h2 style={{fontFamily: "forma-djr-deck, sans-serif", fontStyle: "normal", fontWeight: 100, fontSize: "4rem" }}>
                Reflections 
                <span style={{fontFamily: "dunkel-sans-medium, sans-serif", fontStyle: "italic", fontWeight: 900}}>noir</span>
              </h2>
              <h2 style={{fontFamily: "forma-djr-deck, sans-serif", fontStyle: "normal", fontWeight: 100, fontSize: "2rem", textAlign: "right", width: "100%", marginTop: "-3rem" }}>
                MINTING 
                <span style={{fontFamily: "dunkel-sans-medium, sans-serif", fontStyle: "italic", fontWeight: 900, marginLeft: "0.2rem" }}>4th OCTOBER</span>
              </h2>
              <img src={logo} alt="bithu nft logo" style={{ width: "35%", marginLeft: "auto", display: "block", marginTop: "-2rem"}}/>
              <div style={{marginLeft: "auto"}}>
                <div className="coin-info" style={{textAlign: "right"}}>
                  <span>Total minted  {totalMinted}</span>
                  <span>Total supply 999</span>
                  <span>Maximum of 3 NFTs per wallet {cost} ETH + gas</span>
                  <span>Reflections holders 1st mint is free + gas</span>
                  <span>Every mint after is 0.08 ETH + gas</span>
                </div>
                <div className="banner_buttons">
                  {
                    account ? 
                    <Button lg variant="mint" onClick={() => mintModalHandle()} style={{marginLeft: "auto"}}>
                      {" "}
                      Mint now
                    </Button> :
                    <Button lg variant="mint" disabled style={{marginLeft: "auto"}}>
                      {" "}
                      Click Connect
                    </Button>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="bithu_v1_baner_right">
              <div className="bithu_v1_baner_right_img_sect">
                
                <div className="bithu_v1_baner_right_img_bg">
                 
                </div>
                <div className="bithu_v1_baner_right_img">
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BannerV1Wrapper>
  );
};

export default Banner;
