import SectionTitle from "../../../../common/sectionTitle";

import data from "../../../../assets/data/about/aboutv1";
import AboutStyleWrapper from "./About.style";

const About = () => {
  const { aboutDescription1, aboutDescription2, aboutDescription3 } = data;
  return (
    <AboutStyleWrapper id="about">
      <div className="container">
        <div className="row flex-row-reverse">
          <div className="col-md-12">
            <div className="v1_about_us_right_sect">
              <SectionTitle title="REFLECTIONS NOIR" subtitle="THE STORY SO FAR " />
              <div className="v1_about_us_right_text">
                <p>{aboutDescription1}</p>
                <p>{aboutDescription2}</p>
                <p>{aboutDescription3}</p>
              </div>
            </div>
          </div>
        </div>
        {/* about card  */}
      </div>
    </AboutStyleWrapper>
  );
};

export default About;
