import aboutCardIcon1 from "../../images/icon/aboutus_card_icon1.svg"
import aboutCardIcon2 from "../../images/icon/aboutus_card_icon2.svg"
import aboutCardIcon3 from "../../images/icon/aboutus_card_icon3.svg"

 const data = {
    aboutDescription1: 'Reflections Noir is the follow-up to the hugely successful debut collection by Rich Simmons. After every gallery show, the artist creates a monochromatic version of his favourite piece for his own collection.',
    aboutDescription2: 'Reflections Noir continues this tradition by creating a monochromatic series that celebrates more artists that inspires Simmons work and career.',
    aboutDescription3: 'By offering this exclusively to holders of the original Reflections NFT series, Simmons shows his commitment to building a solid community and offering continued utility, excitement, quality, and inspiration through his collections.',
}

export default data;