import contract from '../contracts/bithuabi.json';
import { ethers } from 'ethers';
import { isMetaMaskInstalled, ethereum, contractAddress } from '../config';
import { addresses } from '../addresses';
import { MerkleTree } from 'merkletreejs';
import { keccak256 } from 'web3-utils';


window.Buffer = window.Buffer || require("buffer").Buffer; 

let mintMessage = '';
let mintCount = 0;
let userWhitelisted = 0;

export { mintMessage, mintCount, userWhitelisted }

export const mint = async (mint_amount) => {
    if(isMetaMaskInstalled()){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const accounts = await provider.send("eth_requestAccounts", []);
        const account = accounts[0];
        const userAddresses = addresses();
        if (account === undefined) {
            return "Error: please connect wallet";
        } else {
            const buf2hex = x => '0x'+x.toString('hex');
            const nftContract = new ethers.Contract(contractAddress(), contract, signer);
            const leaves = userAddresses.map(x => keccak256(x))
            const tree = new MerkleTree(leaves, keccak256, { sortPairs: true });
          
            const leaf = keccak256(account) // address from wallet using walletconnect/metamask
            const proof = tree.getProof(leaf).map(x => buf2hex(x.data));

            const getPrice = async () => {
                let cost = await nftContract.cost();
                return cost;
            }

            const getMintStatus = async () => {
                let publicMintStatus = await nftContract.publicMintStarted();

                return publicMintStatus;
            }
            
            const getMintCountForUser = async () => {
                let userMintCount = await nftContract.balanceOf(account);

                mintCount = userMintCount.toNumber();

                return userMintCount;
            }
            
            await getPrice().then(async(cost) => {
                getMintStatus().then(async (mintStatus) => {
                    getMintCountForUser().then(async (userMintCount) => {
                        let txnHash = "";

                        if(mintStatus) {
                            try {
                                txnHash = await nftContract.mint(mint_amount, {
                                    gasLimit: "285000",
                                    value: ethers.utils.parseEther(localStorage.getItem('totalCost'))
                                }).then(async (txn) => {
                                    let tx = await txn.wait();
                                        
                                    mintMessage = Object.values(tx);

                                    if(mintMessage.length > 0) {
                                        mintMessage = "Success, enjoy your NFT's!";
                                    } else {
                                        mintMessage = "Sorry something went wrong please try again.";
                                    }

                                    alert(mintMessage);

                                    localStorage.setItem('mintMessage', mintMessage);
                                });
    
                                return txnHash;
                            } catch (err) {
                                let errorLength = err.message.length;
                                if(errorLength > 0) {
                                    console.log(err.message); // prints ethers error message containing the json rpc response as it is (along with error stacks from node if sent)
                                
                                    mintMessage = err.message.split('[ See:')[0];

                                    alert(mintMessage);

                                    localStorage.setItem('mintMessage', mintMessage);
                                }
                            }
                        } else {
                            if(proof.length > 0) {
                                try {
                                    txnHash = await nftContract.privateMint(proof,mint_amount, {
                                        gasLimit: "285000",
                                        value: ethers.utils.parseEther(localStorage.getItem('totalCost'))
                                    }).then(async (txn) => {
                                        let tx = await txn.wait();
                                        
                                        mintMessage = Object.values(tx);

                                        if(mintMessage.length > 0) {
                                            mintMessage = "Success, enjoy your NFT's!";
                                        } else {
                                            mintMessage = "Sorry something went wrong please try again.";
                                        }
    
                                        alert(mintMessage);
    
                                        localStorage.setItem('mintMessage', mintMessage);
                                    });

                                    return txnHash;
                                } catch (err) {
                                    let errorLength = err.message.length;
                                    if(errorLength > 0) {
                                        console.log(err.message); // prints ethers error message containing the json rpc response as it is (along with error stacks from node if sent)
                                    
                                        mintMessage = err.message.split('[ See:')[0];
    
                                        alert(mintMessage);
    
                                        localStorage.setItem('mintMessage', mintMessage);
                                    }
                                }
                            } else {
                                userWhitelisted = 1;
                            }
                        }
                    });
                });
            }); 
        }
    }
}

export const getUsersMintCount = () => {
    return mintCount;
}

export const totalMintCount = async () => {
    if(isMetaMaskInstalled()){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        
        if (signer === undefined) {
            return "Error: please connect wallet";
        } else {
            const nftContract = new ethers.Contract(contractAddress(), contract, signer);
            
            await nftContract.totalSupply().then((count) => {
                mintCount = count;

                return count.toNumber();
            })
        }
    }
}

export const currentPrice = async () => {
    if(isMetaMaskInstalled()){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const accounts = await provider.send("eth_requestAccounts", []);

        let account = accounts[0];

        provider.on('accountsChanged', function (accounts) {
            account = accounts[0];
            console.log(account); // Print new address
        });
        
        if (signer === undefined) {
            return "Error: please connect wallet";
        } else {
            const nftContract = new ethers.Contract(contractAddress(), contract, signer);
            
            await nftContract.cost().then((cost) => {
                localStorage.setItem('cost', ethers.utils.formatEther(cost));

                return ethers.utils.formatEther(cost);
            })
        }
    }
}
