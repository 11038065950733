import SectionTitle from "../../../../common/sectionTitle";

import data from "../../../../assets/data/about/dataV2";

import AboutStyleWrapper from "./About.style";

const About = () => {
  const { aboutDescription1, aboutDescription2, aboutDescription3, aboutDescription4, aboutDescription5, artists } = data;
  return (
    <AboutStyleWrapper id="about">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="v1_about_us_right_sect">
              <SectionTitle title="Meet The artist" subtitle="BEHIND THE JOURNEY " style={{marginBottom: "50px"}}/>
              <div style={{display: "flex"}}>
              <div className="ab-content col-lg-6">
                <div>
                {artists?.map((item, i) => (
                  <div key={i} className="ab-item-box mr-30">
                    <img
                      className="author-thumbnail"
                      src={item.thumb}
                      alt="bithu nft about"
                    />
                    <div className="ab-text pt-20">
                      <h5>
                        {item.name}
                        <img src={item.icon} alt="bithu nft about icon" />
                      </h5>
                    </div>
                    <span>{item.description}</span>
                  </div>
                ))}
                </div>
                <div>
                  <p>{aboutDescription1}</p>
                <p>{aboutDescription3}</p>
                </div>
              </div>
              <div className="v1_about_us_right_text col-lg-6">
                <p>{aboutDescription2}</p>
                <p>{aboutDescription4}</p>
                <p>{aboutDescription5}</p>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AboutStyleWrapper>
  );
};

export default About;
