import { useState } from "react";
import contract from '../../../contracts/bithuabi.json';
import { useModal } from "../../../utils/ModalContext";
import { FiX } from "react-icons/fi";
import { addresses } from '../../../addresses';
import Button from "../../button";
import MintModalStyleWrapper from "./MintNow.style";
import hoverShape from "../../../assets/images/icon/hov_shape_L.svg";
import { mint, mintMessage } from '../../../utils/web3mint';
import { useEffect } from "react";
import { ethers } from 'ethers';
import { MerkleTree } from 'merkletreejs';
import { keccak256 } from 'web3-utils';
import { isMetaMaskInstalled, ethereum, contractAddress } from '../../../config';

let finalCost = 0;
let userProof = [];
let privateMintOpen = false;
let mintCount = 0;
let contractPrice = 0;

const MintNowModal = () => {
  const { mintModalHandle } = useModal();
  const [count, setCount] = useState(1);
  const [message, setMessage] = useState('');
  const [totalMinted, setTotalMinted] = useState(0);
  const [cost, setCost] = useState(0);

  const getContractFunctions = async() => {
    if(isMetaMaskInstalled()){
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const accounts = await provider.send("eth_requestAccounts", []);
      const account = accounts[0];
      const nftContract = new ethers.Contract(contractAddress(), contract, signer);
      const buf2hex = x => '0x'+x.toString('hex');
      const userAddresses = addresses();
      const leaves = userAddresses.map(x => keccak256(x))
      const tree = new MerkleTree(leaves, keccak256, { sortPairs: true });
      const leaf = keccak256(account)
      const proof = tree.getProof(leaf).map(x => buf2hex(x.data));
      
      await nftContract.cost().then((rec) => {
        contractPrice = rec.toString();
      });
      
      await nftContract.privateMintStarted().then((rec) => {
        privateMintOpen = rec;
      });
      
      await nftContract.balanceOf(account).then((rec) => {
        mintCount = rec.toNumber();
      });

      userProof = proof;
      
      let web3 = [];

      web3['provider'] = await provider;
      web3['signer'] = await signer;
      web3['account'] = await account;
      web3['nftContract'] = await nftContract;

      return web3;
    }
  }

  const getUserCost = async(curcount) => {
    getContractFunctions();
    let usersCost = 0;
    let curcost = parseFloat(contractPrice);

    console.log(contractPrice.toString())

    checkUserWhitelistStatus();

    if(typeof mintCount === 'number') {
      if(privateMintOpen === true && userProof.length > 0) {
        if(curcount === 1) {
          if(mintCount === 0) {
            usersCost = 0;
          } else {
            usersCost = curcost * curcount;
          }
        } else {
          if(mintCount === 0) {
            usersCost = curcost * (curcount - 1);
            console.log(curcost)
          } else {
            usersCost = curcost * curcount;
          }
        }
      } else {
        usersCost = curcost * curcount;
      }
    }

    usersCost = ethers.utils.formatEther("" + usersCost.toString());

    setCost(usersCost);

    localStorage.setItem('totalCost', usersCost)

    finalCost = usersCost;
  }

  const getTotalMints = async () => {
    await getContractFunctions().then(async(web3) => {
      const nftContract = web3['nftContract'];

      await nftContract.totalSupply().then(async (supply) => {
  
        setTotalMinted(supply.toNumber());
    
        return supply.toNumber();
      });
    })
  }

  const getMintCountForUser = async () => {
    await getContractFunctions().then(async(web3) => {
      const nftContract = web3['nftContract'];

      await nftContract.balanceOf(web3['account']).then(async (userMintCount) => {
        return userMintCount.toNumber();
      });
    })
  }

  const checkUserWhitelistStatus = async() => {
    await getContractFunctions().then(async(web3) => {
      const account = web3['account'];
      
      const buf2hex = x => '0x'+x.toString('hex');
      const leaves = addresses().map(x => keccak256(x))
      const tree = new MerkleTree(leaves, keccak256, { sortPairs: true });

      console.log(buf2hex(tree.getRoot()))
    
      const leaf = keccak256(account) // address from wallet using walletconnect/metamask
      const proof = tree.getProof(leaf).map(x => buf2hex(x.data));

      let whitelisted = false;

      if(proof) {
        if(proof.length > 0) {
          whitelisted = true;
        } else {
          setMessage('Youre not on the whitelist!');
        }
      } else {
        setMessage('Youre not on the whitelist!');
      }

      return whitelisted;
    });
  }
  
  const increaseCount = () => {
    if(count >= 3){
      setMessage('Maximum minting amount 3');
      setCount(3);
    
      getUserCost(3);
    } else {
      setCount(count + 1);
    
      getUserCost(count + 1);
    }
  }

  const dcreaseCount = () => {
    setMessage('')
    if(count <= 1) {
      setMessage('Minimum minting ammount 1.');
      setCount(1);
    
      getUserCost(1);
    } else {
      setCount(count - 1);
    
      getUserCost(count - 1);
    }
  }

  const onChnageCount = (val) => {
    setCount(val);
    
    getUserCost(val);
  }

  const mintNow = async () => {
    if(count > 3){
      setMessage('Maximum minting ammount exceeding!');
    } else if(count < 1){
      setCount(1);
    
      getUserCost(1);
    } else {
      await mint(count).then((tx) => {
        getUserCost(count);
      });
    }
  }
  
  useEffect(() =>{
    getContractFunctions();
    getTotalMints();
    checkUserWhitelistStatus().then(() => {
      getMintCountForUser().then(() => {
        getUserCost(count);
      });
    });
    getUserCost(count);
    setMessage(mintMessage);
  },[]);

  return (
    <>
      <MintModalStyleWrapper className="modal_overlay">
        <div className="mint_modal_box">
          <div className="mint_modal_content">
            <div className="modal_header">
              <h2>Collect YOUR NFTs</h2>
              <button onClick={() => mintModalHandle()}>
                <FiX />
              </button>
            </div>
            <div className="modal_body text-center">
              <div className="mint_count_list">
                <ul>
                  {message &&
                    <li>
                      <p style={{color: "blue", textAlign: "center", width: "100%"}}>
                          {message}
                      </p>
                    </li>
                  }
                  <li>
                    <h5>Remaining</h5>
                    <h5>
                      {totalMinted}/<span>999</span>
                    </h5>
                  </li>
                  <li>
                    <h5>Price</h5>
                    <h5>{cost} ETH</h5>
                  </li>
                  <li>
                    <h5>Quantity</h5>
                    <div className="mint_quantity_sect">
                      <button
                        onClick={() =>
                          count > 1 ? dcreaseCount() : count
                        }
                      >
                        -
                      </button>
                      <input
                        type="text"
                        id="quantity"
                        value={count}
                        onChange={(e) => onChnageCount(e.target.value)}
                      />
                      <button
                        onClick={() =>
                          count <= 3 ? increaseCount() : count
                        }
                      >
                        +
                      </button>
                    </div>
                    <h5>
                      <span>{ cost }</span> ETH
                    </h5>
                  </li>
                </ul>
              </div>
              { message && <p>{message}</p>}
              <div className="modal_mint_btn">
                {totalMinted === 999 
                  ? <Button lg variant="mint" disabled={true} > Mint Now </Button>
                  : <Button lg variant="mint" onClick={() => mintNow() } > Mint Now </Button>
                }
              </div>
            </div>

            <div className="modal_bottom_shape_wrap">
              <span className="modal_bottom_shape shape_left">
                <img src={hoverShape} alt="bithu nft hover shape" />
              </span>
              <span className="modal_bottom_shape shape_right">
                <img src={hoverShape} alt="bithu nft hover shape" />
              </span>
            </div>
          </div>
        </div>
      </MintModalStyleWrapper>
    </>
  );
};

export { finalCost, userProof }

export default MintNowModal;
